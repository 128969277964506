export class StaticData {

  static boroughStatus = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];








  static Subscriber = [
    { label: '999-[1857]-(mobile app test)', value: '999' },
    { label: 'AD1-(1857)-(lintotv)', value: 'lintotv' },
    { label: 'AD10-(1859)-(shammer)', value: 'shammer' }
  ];
  static productStatus = [
    { label: '99967690-[LINTITV]', value: '999' },
    { label: '89876543324', value: 'lintotv' },
    { label: '787875754', value: 'shammer' }
  ];
  static officeLocStatus = [
    { label: 'Active', value: 'active' },
    { label: 'In-active', value: 'inactive' },
  ]; 
  static activeStatus = [
    { label: 'Inactive', value: 0 },
    { label: 'Active', value: 1 },
  ];
  static Permission = [
    { label: 'Manage', value: '' },
    { label: 'View', value: '' },
    { label: 'No Access', value: '' },
  ];
  static NoPermission = [
    { label: 'View', value: '' },
    { label: 'No Access', value: '' },
  ];
  static piedmontConnectAutocomplete = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static ConservationBiller = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static PrimaryLanguage = [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Other', value: 'Other' },
  ];
  static Complaint = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static frozen = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static agentViewable = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static contactByEmail = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static contactBySms = [
    { label: 'No', value: 0 },
    { label: 'Yes', value: 1 }
  ];
  static merchantId = [
    { label: 'Piedmont (166068)', value: 1 },
    { label: 'Colonial (190879)', value: 2 }
  ];
  static inactiveReason = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Suspended', value: 'Suspended' }
  ];
  static expenseMemo = [
    { label: 'Refund', value: 'Refund' },
    { label: 'Reactivation', value: 'Reactivation' },
  ]
  static fundingMethod = [
    { label: 'Draft Bank Account', value: 'Draft Bank Account' },
    { label: 'Payment Card', value: 'Payment Card' },
    { label: 'Direct Deposit', value: 'Direct Deposit' },
  ]
  // static biller = [
  //   { label: 'all state', value: '1' }
  // ];
  // static PiedmontSalesPerson = [
  //   { label: 'Piedmont Payment Serivce', value: 'Piedmont Payment Serivce' },
  //   { label: 'Naumam , Michael', value: 'Naumam , Michael' },
  //   { label: 'Ginter,Matthew ', value: 'Ginter,Matthew' },
  //   { label: 'Augustine,Jami ', value: 'Augustine ,Jami' },

  // ];
  static State = [
    { "label": "AK-Alaska", "value": "AK" },
    { "label": "AL-Alabama", "value": "AL" },
    { "label": "AR-Arkansas", "value": "AR" },
    { "label": "AZ-Arizona", "value": "AZ" },
    { "label": "CA-California", "value": "CA" },
    { "label": "CO-Colorado", "value": "CO" },
    { "label": "CT-Connecticut", "value": "CT" },
    { "label": "DC-Dist. of Columbia", "value": "DC" },
    { "label": "DE-Delaware", "value": "DE" },
    { "label": "FL-Florida", "value": "FL" },
    { "label": "GA-Georgia", "value": "GA" },
    { "label": "GU-Guam", "value": "GU" },
    { "label": "HI-Hawaii", "value": "HI" },
    { "label": "IA-Iowa", "value": "IA" },
    { "label": "ID-Idaho", "value": "ID" },
    { "label": "IL-Illinois", "value": "IL" },
    { "label": "IN-Indiana", "value": "IN" },
    { "label": "KS-Kansas", "value": "KS" },
    { "label": "KY-Kentucky", "value": "KY" },
    { "label": "LA-Louisiana", "value": "LA" },
    { "label": "MA-Massachusetts", "value": "MA" },
    { "label": "MD-Maryland", "value": "MD" },
    { "label": "ME-Maine", "value": "ME" },
    { "label": "MI-Michigan", "value": "MI" },
    { "label": "MN-Minnesota", "value": "MN" },
    { "label": "MO-Missouri", "value": "MO" },
    { "label": "MS-Mississippi", "value": "MS" },
    { "label": "MT-Montana", "value": "MT" },
    { "label": "NC-North Carolina", "value": "NC" },
    { "label": "ND-North Dakota", "value": "ND" },
    { "label": "NE-Nebraska", "value": "NE" },
    { "label": "NH-New Hampshire", "value": "NH" },
    { "label": "NJ-New Jersey", "value": "NJ" },
    { "label": "NM-New Mexico", "value": "NM" },
    { "label": "NV-Nevada", "value": "NV" },
    { "label": "NY-New York", "value": "NY" },
    { "label": "OH-Ohio", "value": "OH" },
    { "label": "OK-Oklahoma", "value": "OK" },
    { "label": "OR-Oregon", "value": "OR" },
    { "label": "PA-Pennsylvania", "value": "PA" },
    { "label": "PR-Puerto Rico", "value": "PR" },
    { "label": "RI-Rhode Island", "value": "RI" },
    { "label": "SC-South Carolina", "value": "SC" },
    { "label": "SD-South Dakota", "value": "SD" },
    { "label": "TN-Tennessee", "value": "TN" },
    { "label": "TX-Texas", "value": "TX" },
    { "label": "UT-Utah", "value": "UT" },
    { "label": "VA-Virginia", "value": "VA" },
    { "label": "VI-Virgin Islands", "value": "VI" },
    { "label": "VT-Vermont", "value": "VT" },
    { "label": "WA-Washington", "value": "WA" },
    { "label": "WI-Wisconsin", "value": "WI" },
    { "label": "WV-West Virginia", "value": "WV" },
    { "label": "WY-Wyoming", "value": "WY" }
  ];

  static agentFeeSplitBusinessAccount = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
  ];
  static agentFeeSplitAccountType = [
    { label: 'Checking Account', value: 'DDA' },
    { label: 'Savings Account', value: 'Savings' },
  ];
  static agentFeeSplitFederalTaxClassification = [
    { label: 'Individual/Sole Proprietor or Single Member LLC', value: 1 },
    { label: 'C Corporation', value: 2 },
    { label: 'S Corporation', value: 3 },
    { label: 'Partnership', value: 4 },
    { label: 'Trust/Estate', value: 5 },
    { label: 'Limited Liability Company (C Corporation)', value: 6 },
    { label: 'Limited Liability Company (S Corporation)', value: 7 },
    { label: 'Limited Liability Company (Partnership)', value: 8 },
  ];
}
