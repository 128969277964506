<header class="header" *ngIf="sysService.isLoggedIn()">
  <div class="p-2">
    <button pButton pRipple type="button" class="p-button-rounded p-button-info p-button-text"
      (click)="op.toggle($event)">
      <i class="pi pi-home" style="font-size: 2rem"></i>
    </button>
    <p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '100%'}">
      <ng-template pTemplate>
        <div class="grid pt-8 pb-8 pr-4" style="background-color: #e6eff7;">
          <div class="col-12 md:col-6 lg:col-4 p-2" (click)="link('/broadband');op.hide()">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-wifi" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Broadband</span>
            </button>
          </div>
          <div class="col-12 md:col-6 lg:col-4 p-2" (click)="link('/digitaltv');op.hide()">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-box" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Digital TV</span>
            </button>
          </div>
          <div class="col-12 md:col-6 lg:col-4 p-2" (click)="link('/outage');op.hide()" >
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-ban" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Outage Details </span>
            </button>
          </div>
          
          <div class="col-12 md:col-6 lg:col-4 p-2">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-user-plus" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Associate</span>
            </button>
          </div>
         
          <div class="col-12 md:col-6 lg:col-4 p-2">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-search" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Subscriber Search TRAI</span>
            </button>
          </div>

         
          <div class="col-12 md:col-6 lg:col-4 p-2">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-link" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Link-internal</span>
            </button>
          </div>
          <!-- <div class="col-12 md:col-6 lg:col-4 p-2">
            <button pButton pRipple class="card p-6" style="width:100%" [disabled]="sysService.loading">
              <i class="pi pi-building" style="font-size: 2rem;"></i>
              <span class="pl-4" style="font-size: 2rem;">Other Business unit</span>
            </button>
          </div> -->
         
        </div>
      </ng-template>
    </p-overlayPanel>


  </div>
  <div class="logo">
    <img src="assets/images/{{sysService.logo}}" alt="topbar-logo">
  </div>

  <div class="p-2">
    <button pButton pRipple type="button" class="p-button-rounded p-button-info p-button-text"
      (click)="menu.toggle($event)">
      <i class="pi pi-list" style="font-size: 2rem"></i>
    </button>
    <p-menu #menu [popup]="true" [model]="menus"></p-menu>
  </div>
</header>

