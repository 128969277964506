import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: 'broadband',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'digitaltv',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'outage',
    loadChildren: () => import('./outage/outage.module').then(m =>m.OutageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
   path: 'ticketdetails',
    loadChildren: () => import('./ticketdetails/ticketdetails.module').then(m => m.ticketdetailsModule)
  },
  
  // {
  //   path: 'product-flat',
  //   loadChildren: () => import('./product-flat/product-flat.module').then(m => m.ProductFlatModule)
  // },
  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
