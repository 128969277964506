import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { FormDefault, Model } from '../components/model.view';
import { View } from '../components/view.component';
import { ViewFactory } from '../components/view.factory.component';
import { ApiService } from '../services/api.service';
import { SysService } from '../services/sys.service';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
type AppTheme = {
  theme: string;
  logo: string;
};

@Component({
  selector: 'f-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  menus: MenuItem[];
  sideNav: Element | undefined;
  wrapper: Element | undefined;
  

  assignedToList: any = [];
  FileTypeList: any = [];
  fileToUpload: File;

  private headView: View = this.viewFactory.newView();

  private themes: {
    [x: string]: any;
    www: AppTheme;
    tcdirect: AppTheme;
    bc: AppTheme;
    c2g: AppTheme;
    primepay: AppTheme;
  } = {
      www: { theme: 'theme-blue', logo: 'logo.png' },
      tcdirect: { theme: 'theme-gray', logo: 'TCLogo.jpg' },
      bc: { theme: 'theme-default', logo: 'logo.png' },
      c2g: { theme: 'theme-green', logo: 'c2g90.png' },
      primepay: { theme: 'theme-blue', logo: 'NBPlogo.png' }
    };

  constructor(
    private route: ActivatedRoute,
    public sysService: SysService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private viewFactory: ViewFactory,
    public messageService: MessageService,
    private router: Router
  ) {  }

  ngOnInit(): void {
  this.headView.of(this.headModel(), false);  
    this.menus = [
      { label: 'Outage Details ',icon:"pi pi-spin pi-spinner" ,command: (event) => { this.link('/outage') } },
        { label: 'Tickets ',icon:"pi pi-ticket" ,command: (event) => { this.link('/broadband') } },
        { label: 'Comments  ',icon:"pi pi-comment", command: (event) => { this.link('/product') } },
        { label: 'Reminder Comments ',icon:"pi pi-bell", command: (event) => { this.link('/') } },
        { label: 'Plan Details ', icon: "pi pi-table", command: (event) => {  } },
      //  {
      //   label: 'Profile',
      //   icon: 'pi pi-user',
      //   command: () => {
      //     this.headView.toNew()
      //   }
      // },
      // {
      //   label: 'Change Password',
      //   icon: 'pi pi-pencil',
      //   command: () => {   
      //     this.sysService.navigate(['/changepassword']);           
      // }
    // },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logout();
        }
      }
    ];
  }

  logout(): void {
    this.sysService.signOut();
  }
  get profile() {
    return this.headView.model;
  }

   link(path) {
    SysService.scrollTo(0);
    this.messageService.clear();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([path]));
  }

  toggleMenu(): void {
    this.sideNav = document.querySelectorAll('.sideNav')[0];
    this.wrapper = document.querySelectorAll('#wrapper')[0];

    if (this.sideNav === undefined || this.wrapper === undefined) {
      return;
    }

    if (this.sideNav.classList.contains('opened')) {
      this.sideNav.classList.remove('opened');
      this.wrapper.classList.remove('menuOpened');
    } else {
      this.sideNav.classList.add('opened');
      this.wrapper.classList.add('menuOpened');
    }
  }

//  private profilemodel(): Model {
//    const formDefault = new FormDefault();
//    return new Model(this.formBuilder.group({
//      id: [,]
//    }), this.sysService, formDefault, '');
//  }
 private headModel(): Model {
   const formDefault = new FormDefault();
   return new Model(this.formBuilder.group({
     FileID:[]
   }), this.sysService, formDefault, '');
 }

  onChange(event): void {
    this.headView.setParam('sessioncontext', event.value);
    this.sysService.siteContext = event.value;
    this.headView.getData('/site/change').then(data => {
      const sel = this.sysService.siteList.filter(d => d.OwnerPartyID === this.sysService.siteContext);
      this.userService.changeSite(data, this.route, this.sysService);
      if (!this.headView.isEmptyList(sel)) {
        const site: any = this.themes[sel[0]?.subDomain?.toLowerCase()];
        if (site) {
          this.sysService.changeTheme(site?.theme, site?.logo);
        }
      }
    });
  }
  selectFile(files: FileList) {
     this.profile.setFile(files, "UserFile");
  }
  async profilePicture(){
    let data = await this.headView.toSaveList('/profile', "")
    if(data){
      this.sysService.getUser().ProfilePic=data.ProfilePic;
    }
  
  }

}
