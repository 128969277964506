

<div class="layout-wrapper" id="wrapper" [ngClass]="{
  menuOpened: sysService.isLoggedIn()
}">
  <!-- <div class="p-bar" *ngIf="sysService.isLoggedIn()"></div> -->

  <f-header></f-header>
  <div class="{{sysService.isLoggedIn() ? 'mc':''}}">
    <p-messages key="msgs"></p-messages>
    <router-outlet></router-outlet>
  </div>
</div>

<footer class="footer">
  &#169; Copyright: Asianet
</footer>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>