import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { StaticService } from './data/static.service';
import { FieldErrorComponent } from './field-error/field-error.component';
import { TableExportComponent } from './table-export/table-export.component';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { AppHeaderComponent } from './header/header.component';
import { AvatarModule } from 'primeng/avatar';
import {DialogModule} from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
@NgModule({
  imports: [CommonModule, ButtonModule, DropdownModule, AvatarModule, FormsModule, MenuModule,OverlayPanelModule,DialogModule,  ReactiveFormsModule, MessagesModule,],
  declarations: [AppHeaderComponent, FieldErrorComponent, TableExportComponent],
  exports: [AppHeaderComponent, FieldErrorComponent, TableExportComponent,
    CommonModule, FormsModule, DialogModule,ConfirmDialogModule,],
  providers: [StaticService,MessageService],
})
export class SharedModule { }
 