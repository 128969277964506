import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants } from '@app/_config';
import { SysService, UserService } from '@app/_common';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';


//@Injectable({
//  providedIn: 'root'
//})
export class MenuService {
  /* Retrun user menu statically or from backend */
  static messageService: MessageService;
  // static dialogService: DialogService;
  public static getMenuUser(isAdmin: boolean, userService: UserService, router: Router, messageService: MessageService) {
    this.messageService = messageService;
    
    // if (userService.isLoggedIn()) {
    //   //Based on role you can return user menu or admin menu   
    //   if (isAdmin) {
    //     return this.getUserMenu(isAdmin, router, userService);
    //   } else {
    //     return this.getAgentMenu(isAdmin, router);
    //   }
    // }
    return []; //no menu
  }

  
  // private static getUserMenu(isAdmin: boolean, router: Router,userService: UserService) {
    // return [{
    //     items: [
    //       { label: 'Tickets ', command: (event) => { this.link('/broadband', router) } },
    //       { label: 'Comments  ', command: (event) => { this.link('/product', router) } },
    //       { label: 'Reminder Comments ', command: (event) => { this.link('/', router) } },
    //       { label: 'Plan Details ', command: (event) => {  } },
    //     ]
    //   }

    // ];
  // }
  


  // items:[
  //   { label: 'Dashboard', icon: 'pi pi-bars', command: (event) => { this.link('/', router) } },
  //   {
  //     label: 'Decision Trees', icon: 'pi pi-sitemap', command: (event) => { this.link('/', router) }

  //   },
  //   {
  //     label: 'Calendar', icon: 'pi pi-calendar', command: (event) => { this.link('/calender', router) }

  //   },
  //   {
  //     label: 'Customers', icon: 'pi pi-users', command: (event) => { this.link('/', router) }

  //   },
  //   {
  //     label: 'Call Log', icon: 'pi pi-phone', command: (event) => { this.link('/', router) }

  //   },
  //   {
  //     label: 'Call Details screen', icon: 'pi pi-book', command: (event) => { this.link('/', router) }

  //   },
  //   {
  //     label: ' Cases', icon: 'pi pi-folder', command: (event) => { this.link('/', router) }

  //   },
  //   {
  //     label: ' Case Details screen ', icon: 'pi pi-file', command: (event) => { this.link('/', router) }

  //   },


  // private static getAgentMenu(isAdmin: boolean, router: Router) {
    
  // }

  // static link(path, router) {
  //   SysService.scrollTo(0);
  //   this.messageService.clear();
  //   router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([path]));
  // }
}

//  static addPersonal(dialogService) {
//     dialogService.open(TaskComponent, {
//       header: 'ADD PERSONAL TASK',    
//       width: '42%',  
//     baseZIndex: 10000,

//    });
//  }
// }

