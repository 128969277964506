import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConstants } from '@app/_config';
import { SysService, UserService } from '@app/_common';
import { StaticData } from './static.data';
@Injectable({
  providedIn: 'root'
})
export class StaticService {

  productStatus: any = [];
  activeStatus: any = [];
  piedmontConnectAutocomplete: any = [];
  merchantId: any = [];
  inactiveReason: any = [];
  biller: any = [];
  // PiedmontSalesPerson: any = [];
  State: any = [];
  Permission: any = [];
  NoPermission: any = [];
  ConservationBiller: any = [];
  Complaint: any =[];
  PrimaryLanguage : any [];
  agentFeeSplitBusinessAccount: any = [];
  agentFeeSplitAccountType: any = [];
  agentFeeSplitFederalTaxClassification: any = [];
  expenseMemo: any = [];
  frozen: any = [];
  agentViewable: any = [];
  contactBySms: any =[];
  fundingMethod:any =[];
  contactByEmail:any=[];
  officeLocStatus:any=[];
  Subscriber:any=[];

  // static service for aliis
  boroughStatus: any = [];

  constructor() {
    this.Subscriber=StaticData.Subscriber;
    this.productStatus = StaticData.productStatus;
    this.activeStatus = StaticData.activeStatus;
    this.piedmontConnectAutocomplete = StaticData.piedmontConnectAutocomplete;
    this.merchantId = StaticData.merchantId;
    this.inactiveReason = StaticData.inactiveReason;
    // this.biller = StaticData.biller;
    // this.PiedmontSalesPerson = StaticData.PiedmontSalesPerson;
    this.frozen = StaticData.frozen;
    this.State = StaticData.State;
    this.contactBySms = StaticData.contactBySms;
    this.contactByEmail = StaticData.contactByEmail;
    this.officeLocStatus = StaticData.officeLocStatus;
    this.agentViewable = StaticData.agentViewable;
    this.Permission = StaticData.Permission;
    this.NoPermission = StaticData.NoPermission;
    this.ConservationBiller = StaticData.ConservationBiller;
    this.Complaint = StaticData.Complaint;
    this.PrimaryLanguage = StaticData.PrimaryLanguage;
    this.agentFeeSplitBusinessAccount = StaticData.agentFeeSplitBusinessAccount;
    this.agentFeeSplitAccountType = StaticData.agentFeeSplitAccountType;
    this.agentFeeSplitFederalTaxClassification = StaticData.agentFeeSplitFederalTaxClassification;
    this.expenseMemo = StaticData.expenseMemo;
    this.fundingMethod = StaticData.fundingMethod;


    // aliis
    this.boroughStatus = StaticData.boroughStatus;
  }
}
