import { Injectable, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Router, NavigationStart, NavigationExtras } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ApiService, UserService } from '@app/_common';
import { AppConfig, AppConstants, AppHeader } from '@app/_config';
import { MenuService } from '../data/menu.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
@Injectable()
// @Injectable({  providedIn: 'root'})
export class SysService {

  get loading(): boolean {
    return this.load;
  }

  constructor(
    private messageService: MessageService,
    private apiService: ApiService,
    private userService: UserService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private router: Router) {
    this.primengConfig.ripple = true;
  }
  popup = false; //transfered from view or viewpopup
  theme = 'theme-blue';
  logo = 'logo.png';
  siteContext: string;
  rows = 20;
  rowsPerPageOptions = [10, 30, 50, 100, 200];

  menuItems: MenuItem[] = []; // Menus for the user
  username: string;
  siteList: any[];
  // Menus for the user
  // loading = false;
  public submitted = false;
  load = false;
  user: any;

  static scrollTo(v = 0): void {
    document.querySelector('body')?.scroll({
      top: v,
      left: 0,
      behavior: 'smooth'
    });
  }
  setLoading(loading: boolean): void {
    this.load = loading;
  }

  async appRefresh(): Promise<void> {
    if (this.menuItems.length === 0) {
      await this.appInit();
    }
  }

  async appInit(): Promise<void> {
    this.menuItems = [];
    this.username = '';
    this.siteList = [];
    this.siteContext = '';
    this.user = this.getUser();
    if (this.isLoggedIn()) {
      if (this.isAdmin()) {
        // this.apiService.get('/site').toPromise().then(data => { this.siteList = data; });
      }

      if (this.user) {
        this.username = this.user.UserName;
        if (!this.isAdmin() && (this.user.UserAgreement === 0 || this.user.SecurityQuestions === 0 || this.user.BestPractice === 0)) {
          this.menuItems = [];
         await this.router.navigate(['/agreement']);
        } else {
          this.menuItems = MenuService.getMenuUser(this.isAdmin(), this.userService, this.router, this.messageService);
        }
      }
    }
  }

  hasPermission(key: any = []): boolean {
    if (this.isAdmin()) {
      return this.userService.hasPermission(key, this.user);
    }
    return false;
  }

  private getKey(key:string):string{
    if(key === ''){
      if(this.popup === true) return 'popmsgs';
      if(this.popup === false) return 'msgs';        
    }
    return key;
  }

  success(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.successAdd(summary, detail, this.getKey(key));
  }

  successAdd(summary: string, detail: string = '', k: string = ''): void {
    this.clearUnload();
    if (summary && summary !== '') {
      let key:string=this.getKey(k);
      this.messageService.add({ severity: 'success', summary, detail, key });
    }
  }

  error(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.errorAdd(summary, detail, this.getKey(key));
  }

  errorAdd(summary: string, detail: string = '', k: string = ''): void {
    // console.log(summary);

    this.clearUnload();
    if (summary && summary !== '') {
      let key:string=this.getKey(k);
      this.messageService.add({ severity: 'error', summary, detail, key });
    }
  }

  info(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.infoAdd(summary, detail, this.getKey(key));
  }

  infoAdd(summary: string, detail: string = '', k: string = '') {
    this.clearUnload();
    if (summary && summary !== '') {
      let key:string=this.getKey(k);
      this.messageService.add({ severity: 'info', summary, detail, key });
    }
  }

  warn(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.warnAdd(summary, detail, this.getKey(key));
  }

  warnAdd(summary: string, detail: string = '', k: string = ''): void {
    this.clearUnload();
    if (summary && summary !== '') {
      let key:string=this.getKey(k);
      this.messageService.add({ severity: 'warn', summary, detail, key });
    }
  }

  clearUnload(): void {
    // this.messageService.clear();
    this.load = false;
  }
  clear(keep: boolean = false): void {
    this.clearUnload();
    if (keep === false) {
      this.messageService.clear();
    }
    this.submitted = false;
  }

  navigate(url: any[], keep: boolean = false, extras?: NavigationExtras): void {
    this.clear(keep);
    this.router.navigate(url);
  }

  getUser(): any {
    if (this.user)
      return this.user;
    else
      this.user = this.userService.getUser();
    return this.user;
  }

  isLoggedIn(): boolean {
    return (this.user && this.user !== null);
  }

  async signOut(): Promise<void> {
    this.user = null;
    this.userService.signOut();
    this.navigate([AppConfig.logout]);
  }


  handleError(err: any, mess: string = 'DEFAULT'): void {
    this.load = false;
    if (err.status === 422) {
      if (err.error && err.error.length > 0) {
        for (let i = 0; i < err.error.length; i++) {
          this.errorAdd(err.error[i]);
        }
      } else {
        this.error(AppConstants.validationFailed);
      }
    }
    else if ([400].includes(err.status)) {
      this.error(AppConstants.err400);
    }
    else {
      this.error(mess === 'DEFAULT' ? AppConstants.errUnknow : mess);
    }
  }

  isAdmin(): any {
    if (this.isLoggedIn()) {
      const user = this.getUser();
      return (user.role_id === 1)
    }
    return false;
  }

  changeTheme(styleName: string, logo: string): void {

    this.theme = styleName;
    this.logo = logo;
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById('theme-style') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${styleName}.css`;
    } else {
      const style = document.createElement('link');
      style.id = 'theme-style';
      style.rel = 'stylesheet';
      // style.type = 'text/stylesheet';
      style.href = `${styleName}.css`;

      head.appendChild(style);
    }

  }

}
